import { style } from 'typestyle';

export const container = style({
	position: 'absolute',
	bottom: '100%',
	left: 0,
	textOverflow: 'ellipsis',
	padding: '3px',
	zIndex: 99,
});

export const summary = style({
	borderTopLeftRadius: '9px',
	borderTopRightRadius: '9px',
	width: '100%',
	display: 'flex',
	alignContent: 'center',
	justifyContent: 'center',
	boxSizing: 'border-box'
});

export const count = style({
	display: 'inline-block',
	marginRight: '3px',
});
export const countLabel = style({
	padding: '0 2px'
});

export const detail = style({
	borderWidth: '1px',
	borderStyle: 'solid',
	borderRadius: '9px',
	zIndex: 100,
	padding: '10px',
	fontWeight: 'normal',
	fontSize: '1.2em',
	whiteSpace: 'nowrap',
	bottom: '105%',
	right: 0,
	left: 'auto',
});

export const log = style({

});
export const info = style({

});
export const warn = style({

});
export const error = style({

});