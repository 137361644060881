import * as React from 'react';
import { Flow, FlowType } from '@edge-trace/model';
import { FlowRootView } from './FlowRootView';
import { FlowFixedView } from './FlowFixedView';
import { FlowOptionalView } from './FlowOptionalView';
import { FlowForkView } from './FlowForkView';
import { FlowBranchRootView } from './FlowBranchRootView';
import { FlowRotateView } from './FlowRotateView';
import { FlowSendView } from './FlowSendView';
import { FlowRespondView } from './FlowRespondView';
import { FlowContainer } from './FlowContainer';

export const treeFactory = (flow: Flow): JSX.Element => {
	switch (flow.nodeType) {
		case 'fork':
			return (
				<FlowContainer flow={flow} hidden>
					{contentFactory(flow)}
				</FlowContainer>
			);
		default:
			return <FlowContainer flow={flow}>{contentFactory(flow)}</FlowContainer>;
	}
};
export const contentFactory = (flow: Flow): JSX.Element => {
	switch (flow.nodeType) {
		case 'root':
			return <FlowRootView flow={flow} />;
		case 'fixed':
			return <FlowFixedView flow={flow} />;
		case 'optional':
			return <FlowOptionalView flow={flow} />;
		case 'fork':
			return <FlowForkView flow={flow} />;
		case 'branch-root':
			return <FlowBranchRootView flow={flow} />;
		case 'rotate':
			return <FlowRotateView flow={flow} />;
		case 'send':
			return <FlowSendView flow={flow} />;
		case 'respond':
			return <FlowRespondView flow={flow} />;
	}
};
