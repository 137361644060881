import * as React from 'react';
import { classes } from 'typestyle';

import * as style from './TimelineLogGroup.style';
import { Log } from '@edge-trace/model/transaction';
import { TimelineLogItem } from '../TimelineLogItem';
import { Icon, IconProps } from '../Icon';
import { selectedStyle } from '../levels';

export type ComponentProps = {
	className?: string;

	/**
	 * An array of log entries
	 */
	logs?: Log[];

	/**
	 * True to show full detail, undefined to show only a count
	 */
	expanded?: boolean;
};

type LogType = Log['type'];

const getCounts = (logs: Log[]): { [type in LogType]: number } => {
	const counts = { log: 0, info: 0, warn: 0, error: 0 };
	for (const log of logs) {
		counts[log.type]++;
	}
	return counts;
}
const iconTypes: { [type in LogType]: React.FunctionComponent<IconProps> } = {
	log: Icon.Log,
	info: Icon.Info,
	warn: Icon.Warn,
	error: Icon.Error,
};

const keysOf = <T extends {}>(obj: T): Array<keyof T> => Object.keys(obj) as unknown as Array<keyof T>;

const TimelineLogGroup: React.FC<ComponentProps> = ({ className, logs, expanded }) => {
	if (!logs || !logs.length) {
		return null;
	}

	if (!expanded) {
		const counts = getCounts(logs);
		return <div className={classes(className, style.summary, style.container)}>
			{keysOf(counts).filter(type => !!counts[type]).map(type => {
				const LogIcon = iconTypes[type];
				return (
					<div key={type} className={style.count}><LogIcon width={16} height={16} />
						<span className={style.countLabel}>{counts[type]}</span>
					</div>
				);
			})}
		</div>;
	}

	return (
		<div className={classes(className, style.detail, style.container, selectedStyle)}>
			{logs.map((log, idx) => (
				<TimelineLogItem key={idx} icon={iconTypes[log.type]} className={style[log.type]}>{log.message}</TimelineLogItem>)
			)}
		</div >
	);
}

TimelineLogGroup.displayName = 'TimelineLogGroup';
export { TimelineLogGroup };
