import { style, cssRaw } from 'typestyle';
import { NestedCSSProperties } from 'typestyle/lib/types';

cssRaw(`
	body { margin: 0; padding: 0; font-size: small; }
`);

export const container = style({
	fontFamily: 'Roboto, sans-serif',
	fontSize: '0.9em',
	fontWeight: 'bold',
	width: '100vw',
	height: '100vh',
	display: 'grid',
	gridTemplateColumns: 'auto 460px',
	gridTemplateRows: 'auto 80px',
});

const scrollable: NestedCSSProperties = {
	overflow: 'auto',
	scrollbarColor: `rgba(0,0,0,0.8) transparent`,
	scrollbarWidth: 'thin',
	'-webkit-overflow-scrolling': 'touch',
	$nest: {
		'&::-webkit-scrollbar': {
			['-webkit-appearance']: 'none',
			// for overflowY
			width: '7px',
			// for overflowX
			height: '7px',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '7px',
			backgroundColor: 'rgba(0,0,0,0.9)',
		},
	},
};

export const main = style(scrollable, {
	padding: '50px',
	background: '#1E1E1E',
	gridColumn: 1,
	gridRow: 1,
});
export const sideBar = style(scrollable, {
	background: '#1E1E1E',
	borderLeft: '2px solid black',
	gridColumn: 2,
	gridRow: 1,
});
export const footer = style({
	background: '#1E1E1E',
	borderTop: '2px solid black',
	gridRow: 2,
	gridColumn: '1 / span 2',
});
