import * as React from 'react';
import {
	BaseData,
	NodeDirectionInbound,
	NodeDirectionOutbound,
	FlowFixed,
} from '@edge-trace/model';
import { classes } from 'typestyle';

import * as style from './FlowFixedView.style';
import { FlowProps } from '../interface';
import { FlowContainer } from '../FlowContainer';

export type ComponentProps = FlowProps<
	FlowFixed<BaseData, NodeDirectionInbound> | FlowFixed<BaseData, NodeDirectionOutbound>
>;

const FlowFixedView: React.FC<ComponentProps> = ({ className, flow }) => {
	return <div className={classes(className, style.container)}>{flow.content.name}</div>;
};
FlowFixedView.displayName = 'FlowFixedView';
export { FlowFixedView };
