import * as React from 'react';
import { classes } from 'typestyle';

import * as style from './TimelineLogItem.style';
import { Icon, IconProps } from 'src/Icon';

export type ComponentProps = {
	className?: string;

	/**
	 * The component to display as the icon
	 */
	icon: React.FunctionComponent<IconProps>;
};

const TimelineLogItem: React.FC<ComponentProps> = ({ className, children, icon: IconType }) => (
	<div className={classes(className, style.container)}>
		<IconType width={16} height={16} />
		{children}
	</div>
);
TimelineLogItem.displayName = 'TimelineLogItem';
export { TimelineLogItem };
