import * as React from 'react';
import { Flow, HistoryTrace } from '@edge-trace/model';
import { classes } from 'typestyle';

import * as style from './FlowContainer.style';
import { FlowProps } from '../interface';
import { treeFactory } from '..';
import { useFlowConnector } from '../useFlowConnector';
import { useFlowContext } from '../../FlowContext';
import { styleByLevel, getLevel, selectedStyle } from '../../levels';

export type ComponentProps = FlowProps<Flow> & {
	hidden?: true;
};

const FlowContainerInner: React.FC<ComponentProps> = ({ className, flow, hidden, children }) => {
	const flowChildren: Flow[] | null = flow.nodeType !== 'respond' ? flow.children : null;
	const [selfRef, childRefs, connector] = useFlowConnector(flowChildren?.length || 0);
	const { usedFlows, onSelectFlow, selectedFlow } = useFlowContext();
	const containerRef = React.useRef<HTMLDivElement>(null);
	React.useEffect(() => {
		if (selectedFlow === flow.id && containerRef.current) {
			containerRef.current.scrollIntoView();
		}
	}, [selectedFlow === flow.id]);

	const history: HistoryTrace | undefined = usedFlows[flow.id];
	const level = getLevel(history);
	const durationMs =
		history && history.startTimestamp && history.endTimeStamp
			? history.endTimeStamp - history.startTimestamp
			: undefined;

	return (
		<div ref={containerRef} className={classes(className, style.container)}>
			{connector}
			<div
				role="button"
				className={classes(
					style.flowSelf,
					hidden && style.nodeHidden,
					selectedFlow === flow.id && selectedStyle,
					level && styleByLevel[level],
				)}
				ref={selfRef}
				onClick={() => onSelectFlow(flow)}
			>
				{durationMs && durationMs > 1 ? <div className={style.badge}>{durationMs}ms</div> : null}
				{children}
			</div>
			{flowChildren && (
				<div className={style.flowChildren}>
					{flowChildren.map((child, idx) => (
						<div key={child.id} className={style.flowChild} ref={childRefs[idx]}>
							{treeFactory(child)}
						</div>
					))}
				</div>
			)}
		</div>
	);
};
FlowContainerInner.displayName = 'FlowContainer';
export const FlowContainer = React.memo<ComponentProps>(FlowContainerInner);
