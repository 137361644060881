import { style } from 'typestyle';

export const container = style({
	display: 'flex',
	flexDirection: 'column',
	position: 'relative',
});

export const badge = style({
	position: 'absolute',
	left: '80%',
	bottom: '80%',
	background: 'red',
	color: 'white',
	padding: '3px',
	borderRadius: '3px',
});

export const flowSelf = style({
	cursor: 'pointer',
	padding: '5px 10px',
	border: '1px solid #6e6e6e',
	borderRadius: '11px',
	alignSelf: 'center',
	position: 'relative',
	zIndex: 1,
});

export const nodeHidden = style({
	visibility: 'hidden',
	padding: 0,
	height: 0,
});

export const flowChildren = style({
	display: 'flex',
	flexFlow: 'row nowrap',
	alignSelf: 'center',
	margin: '20px 5px 0 5px',
});

export const flowChild = style({
	flexGrow: 1,
});
