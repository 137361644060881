import * as React from 'react';
import { classes } from 'typestyle';

import * as style from './FlowForkView.style';
import { FlowProps } from '../interface';
import { Flow } from '@edge-trace/model';
import { FlowContainer } from '../FlowContainer';

export type ComponentProps = FlowProps<Flow>;

const FlowForkView: React.FC<ComponentProps> = ({ className, flow }) => {
	return <div className={classes(className, style.container)}>?</div>;
};
FlowForkView.displayName = 'FlowForkView';
export { FlowForkView };
