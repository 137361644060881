import * as React from 'react';
import { classes } from 'typestyle';

import * as style from './FlowOptionalView.style';
import { FlowProps } from '../interface';
import {
	BaseData,
	FlowOptional,
	NodeDirectionInbound,
	NodeDirectionOutbound,
} from '@edge-trace/model';
import { FlowContainer } from '../FlowContainer';

export type ComponentProps = FlowProps<
	FlowOptional<BaseData, NodeDirectionInbound> | FlowOptional<BaseData, NodeDirectionOutbound>
>;

const FlowOptionalView: React.FC<ComponentProps> = ({ className, flow }) => {
	return <div className={classes(className, style.container)}>({flow.content.name})</div>;
};
FlowOptionalView.displayName = 'FlowOptionalView';
export { FlowOptionalView };
