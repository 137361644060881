import { style } from "typestyle";
import { HistoryTrace } from "@edge-trace/model";

export const selectedStyle = style({
	$nest: {
		'&&': {
			borderColor: 'white',
			background: '#0E0E0E',
			color: 'white',
		},
	},
});

export const styleByLevel = {
	used: style({
		backgroundColor: '#6e6e6e',
		color: '#0a0a0a',
	}),
	unused: style({
		background: '#1E1E1E',
		color: '#6e6e6e',
	}),
	warn: style({
		background: 'yellow',
	}),
	error: style({
		background: 'red',
	}),
};

export const getLevel = (historyTrace?: HistoryTrace): 'unused' | 'used' | 'warn' | 'error' => {
	return !historyTrace
		? 'unused'
		: historyTrace.logs?.some(log => log.type === 'error')
			? 'error'
			: historyTrace.logs?.some(log => log.type === 'warn')
				? 'warn'
				: 'used';
}