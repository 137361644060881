import * as React from 'react';

export const useFlowConnector = (
	count: number,
): [React.MutableRefObject<HTMLDivElement | null>, React.MutableRefObject<HTMLDivElement | null>[], JSX.Element | null] => {
	const [generated, setGenerated] = React.useState<JSX.Element | null>(null);
	const node = React.useRef<HTMLDivElement>(null);
	const refs = new Array(count).fill(null).map(_el => React.useRef<HTMLDivElement>(null));
	React.useLayoutEffect(() => {
		window.requestAnimationFrame(() => {
			if (!node.current || !count) {
				return;
			}

			// find the start of the connector
			const startX = node.current.offsetLeft + node.current.clientWidth / 2 + 1;
			const startY = node.current.offsetTop + node.current.clientHeight;

			if (count === 1) {
				const child = refs[0];
				if (!child.current) {
					return;
				}

				// find the end of the single connector
				const endX = startX;
				const endY = child.current.offsetTop;

				setGenerated(
					<svg style={{ position: 'absolute', zIndex: 0, width: '100%', height: '100%' }}>
						<line stroke="#6e6e6e" x1={startX} y1={startY} x2={endX} y2={endY} />
					</svg>,
				);
			} else if (count > 1) {
				if (refs.some(ref => !ref.current)) {
					return;
				}

				// find the ends of the multi connector
				const ends = refs
					.map(ref => ref.current)
					.map(child => {
						return { x: child!.offsetLeft + child!.clientWidth / 2, y: child!.offsetTop };
					});

				// get the dividing line position
				let minEndY = ends[0].y;
				let minEndX = ends[0].x;
				let maxEndX = ends[0].x;
				ends.forEach(end => {
					minEndY = end.y < minEndY ? end.y : minEndY;
					minEndX = end.x < minEndX ? end.x : minEndX;
					maxEndX = end.x > maxEndX ? end.x : maxEndX;
				});
				const midY = (minEndY + startY) / 2;

				setGenerated(
					<svg style={{ position: 'absolute', zIndex: 0, width: '100%', height: '100%' }}>
						<line stroke="#6e6e6e" x1={startX} y1={startY} x2={startX} y2={midY} />
						<line stroke="#6e6e6e" x1={minEndX} y1={midY} x2={maxEndX} y2={midY} />
						{ends.map(end => (
							<line stroke="#6e6e6e" x1={end.x} y1={midY} x2={end.x} y2={end.y} />
						))}
					</svg>,
				);
			}
		});
	}, []);
	return [node, refs, generated];
};
