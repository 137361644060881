import * as React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { FlowRoot } from '@edge-trace/model';

import * as style from './FlowView.style';
import { treeFactory } from '../Flow';
import { classes } from 'typestyle';

export type ComponentProps = {
	className?: string;
	flow: FlowRoot;
};

const FlowView: React.FC<ComponentProps> = ({ className, flow }) => {
	const [isScrolling, setIsScrolling] = React.useState(false);
	return (
		<ScrollContainer
			className={classes(className, !isScrolling && style.smoothScroll)}
			onStartScroll={() => setIsScrolling(true)}
			onEndScroll={() => setIsScrolling(false)}
			horizontal
			vertical
			hideScrollbars={false}
		>
			<div className={style.shell}>
				<div className={style.outer}>
					<div className={style.inner}>{treeFactory(flow)}</div>
				</div>
			</div>
		</ScrollContainer>
	);
};
FlowView.displayName = 'FlowView';
export { FlowView };
