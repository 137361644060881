import { style } from 'typestyle';

export const container = style({
	position: 'relative',
	display: 'flex',
	justifyContent: 'center',
	alignContent: 'center',
	border: '1px solid',
	width: '90%',
	borderRadius: '9px',
	margin: '1px 2px',
	padding: '0 4px',
});

export const withLogs = style({
	$nest: {
		'&&': {
			borderTopLeftRadius: 0,
			borderTopRightRadius: 0,
		}
	}
})

export const selected = style({
	$nest: {
		'&&&': {
			borderTopLeftRadius: '9px',
			borderTopRightRadius: '9px',
		},
	},
});
