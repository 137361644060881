import * as React from 'react';
import { render } from 'react-dom';
import { Trace } from '@edge-trace/model';
import { Viewer } from './Viewer';

declare global {
	interface Window {
		trace?: Trace;
	}
}

if (window.trace) {
	const div = document.createElement('div');
	document.body.appendChild(div);
	try {
		render(<Viewer trace={window.trace} />, div);
	} catch (err) {
		console.error(err);
	}
}
