import * as React from 'react';
import { classes } from 'typestyle';

import * as style from './TimelineBar.style';
import { HistoryTrace } from '@edge-trace/model';
import { useFlowContext } from '../FlowContext';
import { TimelineItem } from '../TimelineItem';

export type ComponentProps = {
	className?: string;
	history: HistoryTrace[];
};

const TimelineBar: React.FC<ComponentProps> = ({ className }) => {
	const { trace, flowsById } = useFlowContext();
	return (
		<div className={classes(className, style.container)}>
			{trace?.history
				.filter(item => flowsById[item.flow].nodeType !== 'fork')
				.map(item => (
					<TimelineItem key={item.flow} historyTrace={item} />
				))}
		</div>
	);
};
TimelineBar.displayName = 'TimelineBar';
export { TimelineBar };
