import * as React from 'react';
import { classes } from 'typestyle';

import * as style from './FlowSendView.style';
import { FlowProps } from '../interface';
import { Flow } from '@edge-trace/model';
import { TitleAndSubtitle } from '../../TitleAndSubtitle';

export type ComponentProps = FlowProps<Flow>;

const FlowSendView: React.FC<ComponentProps> = ({ className, flow }) => {
	return (
		<div className={classes(className, style.container)}>
			<TitleAndSubtitle
				title="Send"
				subtitle={flow.nodeType === 'send' ? flow.content.request : undefined}
			/>
		</div>
	);
};
FlowSendView.displayName = 'FlowSendView';
export { FlowSendView };
