import * as React from 'react';
import { FlowRoot } from '@edge-trace/model';
import { classes } from 'typestyle';

import * as style from './FlowRootView.style';
import { FlowProps } from '../interface';
import { FlowContainer } from '../FlowContainer';

export type ComponentProps = FlowProps<FlowRoot>;

const FlowRootView: React.FC<ComponentProps> = ({ className, flow }) => {
	return <div className={classes(className, style.container)}>Start</div>;
};
FlowRootView.displayName = 'FlowRootView';
export { FlowRootView };
