import * as React from 'react';

import * as style from './TitleAndSubtitle.style';

export type ComponentProps = {
	title: string;
	subtitle?: string;
};

const TitleAndSubtitle: React.FC<ComponentProps> = ({ title, subtitle }) => (
	<>
		<div className={style.title}>{title}</div>
		{subtitle && <div className={style.subtitle}>{subtitle}</div>}
	</>
);
TitleAndSubtitle.displayName = 'TitleAndSubtitle';
export { TitleAndSubtitle };
