import * as React from 'react';
import { classes } from 'typestyle';
import { HistoryTrace } from '@edge-trace/model';

import * as style from './TimelineItem.style';
import { useFlowContext } from '../FlowContext';
import { contentFactory } from '../Flow';
import { getLevel, styleByLevel, selectedStyle } from '../levels';
import { TimelineLogGroup } from '../TimelineLogGroup';

export type ComponentProps = {
	className?: string;
	historyTrace: HistoryTrace;
};

const TimelineItem: React.FC<ComponentProps> = ({ className, historyTrace }) => {
	const { onSelectFlow, selectedFlow, flowsById } = useFlowContext();
	const historyTraceFlow = flowsById[historyTrace.flow];
	const level = getLevel(historyTrace);
	return (
		<div
			className={classes(
				style.container,
				className,
				level && styleByLevel[level],
				!!historyTrace.logs && !!historyTrace.logs.length && style.withLogs,
				selectedFlow === historyTrace.flow && selectedStyle,
				selectedFlow === historyTrace.flow && style.selected,
			)}
			onMouseEnter={() => onSelectFlow(flowsById[historyTrace.flow])}
		>
			<TimelineLogGroup
				className={classes(
					level && styleByLevel[level],
					selectedFlow === historyTrace.flow && selectedStyle,
				)}
				logs={historyTrace.logs}
				expanded={selectedFlow === historyTrace.flow || undefined}
			/>
			{contentFactory(historyTraceFlow)}
		</div>
	);
};
TimelineItem.displayName = 'TimelineItem';
export { TimelineItem };
