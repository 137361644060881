import * as React from 'react';
import { Trace, Flow, HistoryTrace } from '@edge-trace/model';

export type FlowContextData = {
	selectedFlow?: number;
	usedFlows: Record<number, HistoryTrace>;
	onSelectFlow: (flow: Flow) => void;
	flowsById: Record<number, Flow>;
	trace?: Trace;
};

export type ComponentProps = {
	trace: Trace;
};

const FlowContext = React.createContext<FlowContextData>({
	usedFlows: {},
	onSelectFlow: _flow => void 1,
	selectedFlow: 1,
	flowsById: {},
});

export const FlowContextProvider: React.FC<ComponentProps> = ({ trace, children }) => {
	const flowsById: Record<number, Flow> = React.useMemo(() => {
		const allFlows: Record<number, Flow> = {};
		const recurseInto = (flow: Flow) => {
			allFlows[flow.id] = flow;
			if (flow.nodeType !== 'respond') {
				flow.children?.forEach(recurseInto);
			}
		};
		recurseInto(trace.flow);
		return allFlows;
	}, [trace]);

	const usedFlows: Record<number, HistoryTrace> = React.useMemo(() => {
		const used: Record<number, HistoryTrace> = {};
		trace.history.forEach(item => (used[item.flow] = item));
		return used;
	}, [trace]);

	const [selectedFlow, setSelectedFlow] = React.useState<Flow>(trace.flow);
	return (
		<FlowContext.Provider
			value={{
				flowsById,
				selectedFlow: selectedFlow?.id,
				usedFlows,
				onSelectFlow: setSelectedFlow,
				trace,
			}}
		>
			{children}
		</FlowContext.Provider>
	);
};
FlowContextProvider.displayName = 'FlowContextProvider';

export const useFlowContext = () => React.useContext(FlowContext);
