import * as React from 'react';
import { classes } from 'typestyle';

import * as style from './FlowRotateView.style';
import { FlowProps } from '../interface';
import { Flow } from '@edge-trace/model';
import { FlowContainer } from '../FlowContainer';

export type ComponentProps = FlowProps<Flow>;

const FlowRotateView: React.FC<ComponentProps> = ({ className, flow }) => {
	return <div className={classes(className, style.container)}>Rotate</div>;
};
FlowRotateView.displayName = 'FlowRotateView';
export { FlowRotateView };
