import { style, cssRaw } from 'typestyle';

export const container = style({
	color: 'white',
});

cssRaw(`
.react-json-view {
	background-color: transparent !important;
	font-size: 1.2em;
}
`);
