import * as React from 'react';
import { Trace } from '@edge-trace/model';

import * as style from './Viewer.style';
import { FlowView } from '../FlowView';
import { TimelineBar } from '../TimelineBar';
import { InfoBar } from '../InfoBar';
import { FlowContextProvider } from '../FlowContext';

export type ComponentProps = {
	trace: Trace;
};

const Viewer: React.FC<ComponentProps> = ({ trace }) => (
	<FlowContextProvider trace={trace}>
		<div className={style.container}>
			<FlowView flow={trace.flow} className={style.main} />
			<InfoBar history={trace.history} className={style.sideBar} />
			<TimelineBar history={trace.history} className={style.footer} />
		</div>
	</FlowContextProvider>
);
Viewer.displayName = 'Viewer';
export { Viewer };
