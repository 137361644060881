import * as React from 'react';
import { classes } from 'typestyle';

import * as style from './FlowRespondView.style';
import { FlowProps } from '../interface';
import { Flow } from '@edge-trace/model';
import { TitleAndSubtitle } from '../../TitleAndSubtitle';

export type ComponentProps = FlowProps<Flow>;

const FlowRespondView: React.FC<ComponentProps> = ({ className, flow }) => {
	return (
		<div className={classes(className, style.container)}>
			<TitleAndSubtitle
				title="Respond"
				subtitle={flow.nodeType === 'respond' ? flow.content.response : undefined}
			/>
		</div>
	);
};
FlowRespondView.displayName = 'FlowRespondView';
export { FlowRespondView };
