import * as React from 'react';

export type ComponentProps = {
	className?: string;

	width: number;

	height: number;
};

const Attribution: React.FC<ComponentProps> = ({ className }) => (
	<div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
);

const Error: React.FC<ComponentProps> = ({ className, width, height }) => (
	<svg
		className={className}
		height={`${height}px`}
		width={`${width}px`}
		viewBox="0 0 512 512"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="m436.332031 75.441406c-49.53125-49.523437-114.96875-75.441406-180.40625-75.441406-65.433593 0-130.875 25.917969-180.40625 75.441406-100.691406 100.695313-100.691406 260.121094 0 360.8125 49.832031 49.828125 114.972657 75.746094 180.40625 75.746094 65.4375 0 130.578125-25.917969 180.40625-75.746094 100.695313-100.691406 100.695313-260.117187 0-360.8125zm-180.40625 375.824219c-50.125 0-99.960937-19.511719-138.078125-57.335937-67.539062-67.835938-76.246094-173.199219-21.015625-251.246094l159.09375 159.09375 113.167969 113.164062c-34.21875 24.316406-73.839844 36.324219-113.167969 36.324219zm159.09375-82.25-159.09375-159.09375-113.167969-113.164063c34.222657-24.316406 73.84375-36.324218 113.167969-36.324218 50.128907 0 99.960938 19.511718 138.082031 57.335937 67.539063 67.839844 76.246094 173.199219 21.011719 251.246094zm0 0"
			fill="#ff6c6c"
		/>
		<path
			d="m394.007812 117.769531c67.539063 67.835938 76.246094 173.199219 21.011719 251.246094l-159.09375-159.09375v91.855469l113.167969 113.164062c-34.21875 24.316406-73.839844 36.324219-113.167969 36.324219v60.734375c65.4375 0 130.578125-25.917969 180.40625-75.746094 100.695313-100.691406 100.695313-260.117187 0-360.8125-49.53125-49.523437-114.96875-75.441406-180.40625-75.441406v60.433594c50.128907 0 99.960938 19.511718 138.082031 57.335937zm0 0"
			fill="#ff4949"
		/>
	</svg>
);
Error.displayName = 'Icon.Error';

const Warn: React.FC<ComponentProps> = ({ className, width, height }) => (
	<svg
		className={className}
		height={`${height}px`}
		width={`${width}px`}
		viewBox="0 -18 512.00021 512"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="m502.578125 364.800781-180.878906-326.101562c-13.5-24-38.101563-38.699219-65.699219-38.699219s-52.199219 14.699219-65.699219 38.699219l-180.878906 326.101562c-12.902344 23.398438-12.601563 51.300781 1.199219 74.398438 13.5 23.101562 37.800781 36.902343 64.5 36.902343h361.757812c26.699219 0 51-13.800781 64.5-36.902343 13.800782-23.097657 14.101563-51 1.199219-74.398438zm0 0"
			fill="#fdbf00"
		/>
		<path
			d="m501.378906 439.199219c-13.5 23.101562-37.796875 36.902343-64.5 36.902343h-180.878906v-476.101562c27.597656 0 52.199219 14.699219 65.699219 38.699219l180.878906 326.101562c12.902344 23.398438 12.601563 51.300781-1.199219 74.398438zm0 0"
			fill="#ff9f00"
		/>
		<path d="m211 86.101562v225h90v-225zm0 0" fill="#5a5a5a" />
		<path
			d="m256 341.101562c-24.902344 0-45 20.097657-45 45 0 24.898438 20.097656 45 45 45s45-20.101562 45-45c0-24.902343-20.097656-45-45-45zm0 0"
			fill="#5a5a5a"
		/>
		<g fill="#444">
			<path d="m301 386.101562c0 24.898438-20.097656 45-45 45v-90c24.902344 0 45 20.097657 45 45zm0 0" />
			<path d="m256 86.101562h45v225h-45zm0 0" />
		</g>
	</svg>
);
Warn.displayName = 'Icon.Warn';

const Info: React.FC<ComponentProps> = ({ className, width, height }) => (
	<svg
		className={className}
		height={`${height}px`}
		width={`${width}px`}
		viewBox="0 0 512 512"
		xmlns="http://www.w3.org/2000/svg">
		<path d="m0 0h512v512h-512z" fill="#4bb9ec" />
		<path d="m256 0h256v512h-256zm0 0" fill="#00a0d8" />
		<path
			d="m301 376v-195h-135v30c24.902344 0 45 20.097656 45 45v120c0 24.898438-20.097656 45-45 45v30h180v-30c-24.902344 0-45-20.101562-45-45zm0 0"
			fill="#deecf1"
		/>
		<path
			d="m256 61c-24.902344 0-45 20.097656-45 45 0 24.898438 20.097656 45 45 45s45-20.101562 45-45c0-24.902344-20.097656-45-45-45zm0 0"
			fill="#deecf1"
		/>
		<g fill="#c6e2e7">
			<path d="m256 151v-90c24.902344 0 45 20.097656 45 45 0 24.898438-20.097656 45-45 45zm0 0" />
			<path d="m346 421v30h-90v-270h45v195c0 24.898438 20.097656 45 45 45zm0 0" />
		</g>
	</svg>
);
Info.displayName = 'Icon.Info';

const Log: React.FC<ComponentProps> = ({ className, width, height }) => (
	<svg
		className={className}
		height={`${height}px`}
		width={`${width}px`}
		viewBox="0 0 512 512"
		xmlns="http://www.w3.org/2000/svg">
		<path d="m0 0h512v512h-512z" fill="#4bb9ec" />
		<path d="m256 0h256v512h-256zm0 0" fill="#00a0d8" />
		<path
			d="m372.101562 149.800781c-14.402343-55.800781-64.203124-88.800781-116.101562-88.800781-25.5 0-51.300781 7.800781-73.5 25.199219-39 30.300781-46.5 66.601562-46.5 109.800781h90c0-16.5-1.800781-28.5 11.699219-38.699219 5.699219-4.199219 12-6.300781 18.300781-6.300781 12.898438 0 25.5 8.097656 28.800781 21.300781 5.097657 20.097657-7.800781 32.097657-16.199219 36-4.5 2.097657-8.699218 4.5-12.601562 6.898438-27.898438 18.601562-45 51-45 86.699219v29.101562h90v-29.101562c0-6 2.703125-10.800782 5.402344-12 52.796875-24.296876 80.398437-83.398438 65.699218-140.097657zm0 0"
			fill="#deecf1"
		/>
		<path
			d="m256 361c-24.902344 0-45 20.097656-45 45 0 24.898438 20.097656 45 45 45s45-20.101562 45-45c0-24.902344-20.097656-45-45-45zm0 0"
			fill="#deecf1"
		/>
		<g fill="#c6e2e7">
			<path d="m301 406c0 24.898438-20.097656 45-45 45v-90c24.902344 0 45 20.097656 45 45zm0 0" />
			<path d="m306.398438 289.898438c-2.699219 1.199218-5.398438 6-5.398438 12v29.101562h-45v-115.800781c3.898438-2.398438 8.101562-4.800781 12.597656-6.898438 8.402344-3.902343 21.300782-15.902343 16.203125-36-3.300781-13.203125-15.902343-21.300781-28.800781-21.300781v-90c51.898438 0 101.699219 33 116.101562 88.800781 14.699219 56.699219-12.902343 115.800781-65.703124 140.097657zm0 0" />
		</g>
	</svg>
);
Log.displayName = 'Icon.Log';

export { Attribution, Error, Warn, Info, Log };
