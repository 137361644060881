import * as React from 'react';
import JsonView from 'react-json-view';
import { classes } from 'typestyle';

import * as style from './InfoBar.style';
import { HistoryTrace } from '@edge-trace/model';
import { useFlowContext } from '../FlowContext';

export type ComponentProps = {
	className?: string;
	history: HistoryTrace[];
};

const InfoBar: React.FC<ComponentProps> = ({ className }) => {
	const { selectedFlow, usedFlows, trace } = useFlowContext();
	const { flow, history, ...rest } = trace || {};
	const selectedTrace = selectedFlow && usedFlows[selectedFlow];
	return (
		<div className={classes(className, style.container)}>
			{selectedFlow && selectedTrace && (
				<>
					<h1>Node</h1>
					<JsonView sortKeys src={selectedTrace} collapsed={false} displayDataTypes={false} theme="hopscotch" />
				</>
			)}
			<h1>Transaction</h1>
			<JsonView sortKeys src={rest} collapsed={false} displayDataTypes={false} theme="hopscotch" />
		</div>
	);
};
InfoBar.displayName = 'InfoBar';
export { InfoBar };
